<template>
  <div class="app-bank-list">
    <b-row>
      <b-col cols="12">
        <good-data-table
          ref="bank"
          :button-label="$manMudra.getters.isSuperAdmin() ? 'Add Bank' : ''"
          :columns="eventColumns"
          :show-search="false"
          :total-column="'response.entries'"
          :data-column="'response.data'"
          :api-endpoint="'/api/Bank/ListAsync'"
          :extra-params="extraParams"
          :export-columns="exportColumns"
          :export-api-endpoint="'/api/Bank/ExportListAsync'"
          @add-new-info="$helpers.goTo(that, 'group-bank-add')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { appDeleteBank } from '@/@core/services/groups'
import {
  showDangerNotification,
  showSuccessNotification,
} from '@/@core/comp-functions/ui/app'

export default {
  components: {
    GoodDataTable,
    BRow,
    BCol,
  },
  data() {
    return {
      that: this,
      eventColumns: [
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'A/C',
          field: 'accountNumber',
        },
        {
          label: 'Branch',
          field: 'branchName',
        },
        {
          label: 'IFSC',
          field: 'ifsc',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.$helpers.goTo(this, 'group-bank-edit', {
                  bankId: props.id,
                })
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('delete'),
              text: 'Delete',
              action: props => {
                this.deleteBank(props.id)
              },
            },
          ].filter(x => (window.ManMudra.getters.isSuperAdmin() ? x : x.text !== 'Edit')),
        },
      ],
      exportColumns: [
        {
          label: 'BankName',
          value: 'name',
        },
        {
          label: 'Branch',
          value: 'branchName',
        },
        {
          label: 'AccountNo',
          value: 'accountNumber',
        },
        {
          label: 'IFSC',
          value: 'ifsc',
        },
        {
          label: 'ContactPerson',
          value: 'contactPerson',
        },
        {
          label: 'MobileNo',
          value: 'mobileNo',
        },
        {
          label: 'LandlineNo',
          value: 'landlineNo',
        },
        {
          label: 'State Name',
          value: 'address.stateName',
        },
        {
          label: 'District Name',
          value: 'address.districtName',
        },
        {
          label: 'SubDistrict Name',
          value: 'address.subDistrictName',
        },
        {
          label: 'Block Name',
          value: 'address.blockName',
        },
        {
          label: 'Panchayat Name',
          value: 'address.panchayatName',
        },
        {
          label: 'CityOrVillage',
          value: 'address.cityOrVillage',
        },
        {
          label: 'PinCode',
          value: 'address.pinCode',
        },
        {
          label: 'Email',
          value: 'address.email',
        },
      ],
    }
  },

  computed: {
    unionSelected() {
      return this.$store.getters['groups/currentUnion']
    },
    extraParams() {
      if (!this.unionSelected) return ''
      return `unionId=${this.unionSelected.id}`
    },
  },
  watch: {
    unionSelected: {
      immediate: true,
      handler() {
        setTimeout(() => {
          this.runInitial()
        }, 150)
      },
    },
  },
  methods: {
    runInitial() {
      if (this.$refs.bank) {
        this.$refs.bank.loadItems()
      }
    },
    deleteBank(bankId) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Do you really want to delete?')) {
        appDeleteBank({ id: bankId }).then(({ data }) => {
          if (data.succeeded) {
            showSuccessNotification(this, data.message)
            this.$refs.bank.loadItems()
          } else {
            showDangerNotification(this, data.message)
          }
        })
      }
    },
  },
}
</script>
